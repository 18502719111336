
// Detect Native Android Browser
// function detect_native_android_browser() {
//   var ua = navigator.userAgent;
//   var is_native_android = ((ua.indexOf('Mozilla/5.0') > -1 && ua.indexOf('Android ') > -1 && ua.indexOf('AppleWebKit') > -1) && (ua.indexOf('Version') > -1));
//   var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
//   if(is_native_android === true && isChrome === false) {
//     jQuery('body').addClass('native-android-browser');
//   }
// }

// Detect Internet Explorer
function detect_IE() {
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf('MSIE ');
    var trident = ua.indexOf('Trident/');
    if (msie > 0) {
        // IE 9 or older => return version number
        var isIE = document.all && !window.atob;
        if(isIE) {
          jQuery('body').addClass('ie-9-or-older');
          // if (window.location.href !== "/browser-outdated/") {
              // window.location="/browser-outdated/";
          // }
        }
    }
    if (trident > 0) {
        // IE 11 (or newer) => return version number
        var rv = ua.indexOf('rv:');
        jQuery('body').addClass('ie-11-or-newer');
        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }
    // other browser
    return false;
}

// // Apple Device Detect
// function device_detect() {
//   var iOS = ['iPad', 'iPhone', 'iPod'].indexOf(navigator.platform) >= 0;
//   if(iOS === true) {
//     jQuery('body').addClass('apple-device');
//   } 
// }
