/*
These functions make sure WordPress
and Foundation play nice together.
*/

jQuery(document).ready(function() {

  // Evolving function for WCAG guidelines
  v8_wcag();

  // Detect Internet Explorer
  detect_IE();
  // leaving_site();

  // Detect Native Android Browser
  // detect_native_android_browser();

  // Apple Device Detect
  // device_detect();

  AOS.init({
    disable: 'mobile',
    once: true
  });

  baguetteBox.run('.gallery', {
    animation: 'fadeIn',
    titleTag: false,
    captions: false
  });

  var scroll = new SmoothScroll('a[href*="#"]');

  // Gravity Forms Placeholder Clearing
  // gravity_forms_placeholders();

  // Custom DD Slick Dropdown Menus (Select Box Replacement)
  // ddslick();

  // Inline SVG replacement
  // inline_svg_replacement();

  // Isotope Masonry
  // isotope_masonry();

  // Event Target Testing
  jQuery( document ).click(function(event) {

  });

  // Window scroll detection
  jQuery(window).bind("scroll", function(event) {        

  });

  // Remove empty P tags created by WP inside of Accordion and Orbit
  jQuery('.accordion p:empty, .orbit p:empty').remove();

	 // Makes sure last grid item floats left
   jQuery('.archive-grid .columns').last().addClass( 'end' );

	// Adds Flex Video to YouTube and Vimeo Embeds
  jQuery('iframe[src*="youtube.com"], iframe[src*="vimeo.com"]').each(function() {
    if ( jQuery(this).innerWidth() / jQuery(this).innerHeight() > 1.5 ) {
      jQuery(this).wrap("<div class='widescreen flex-video'/>");
    } else {
      jQuery(this).wrap("<div class='flex-video'/>");
    }
  });

});
